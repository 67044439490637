.header {
    width: 100%;
    height: 100px;
    padding: 0 20px 0;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    border-top: 20px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: static;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2);
}

.header_sticky {
    position: sticky;
    top: 0px;
    z-index: 1;
}

.header__company {
    /* margin-left: 20px; */
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline 3px solid;
}

.header__nav-list {
    /* margin-right: 20px; */
    list-style: none;
    display: flex;
    /* gap: 20px; */
}

.header__nav_mobile {
    position: fixed;
    justify-content: end;
    visibility: hidden;
    opacity: 0;
    transition: visibility .4s linear, opacity .4s ease;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, .3);
}

.header__nav_mobile_active{
    visibility: visible;
    opacity: 1;
    z-index: 1;
    /* transform: translateX(-100%); */
}

.header__nav-list_mobile {
    width: 68%;
    height: 100%;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 28px;
    transform: translateX(100%);
    transition: all .4s linear;
}

.header__nav-list_mobile_active {
    transform: translateX(0%);
}

/* .header__nav-item {
    height: ;
} */

.header__link {
    padding: 0 10px;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 18px;
    height: 80px;
    line-height: 80px;
    color: rgb(90, 90, 90);
    transition: all .4s ease;
}

.header__link:hover {
    opacity: 1;
    color: black;
    background-color:orange;
    border-bottom: 10px solid green;
}

.header__link_company {
    /* margin-left: 20px; */
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline 3px solid;
}

.header__link_company:hover {
    opacity: 1;
    color: black;
    background-color: white;
    border-bottom: none;
}

@media screen and (max-width: 950px) {
    .header__company {
        /* font-size: 18px; */
    }
    
    /* .header__nav-list {
        gap: 10px;
    } */
    
    .header__link {
        padding: 0 5px;
        font-size: 15px;
    }

    .header__link_company {
        font-size: 20px;
    }
    
}

@media screen and (max-width: 800px) {
    .header__link_mobile {
        display: block;
        width: 100%;
        height: 100%;
        text-align: start;
        font-size: 22px;
        font-weight: 500;
    }

    .header__link_mobile:hover {
        background-color: #FAFAFA;
        border-bottom: none;
    }

    .header__nav-list_mobile {
        gap: 0;
    }

    .header__nav-item_mobile {
        box-sizing: border-box;
        /* padding: 20px 0 20px 40px; */
        padding-left: 40px;
        width: 100%;
    }

    .header__nav-item_mobile:nth-child(1) {
        margin-top: 150px;
    }
}

@media screen and (max-width: 580px) {
    .header__link_mobile {
        font-size: 18px;
        height: 60px;
        line-height: 60px;
    }
    .header__nav-item_mobile {
        padding-left: 20px;
    }

    .header__nav-item_mobile:nth-child(1) {
        margin-top: 120px;
    }
}

@media screen and (max-width: 420px) {
    .header__nav-list_mobile {
        width: 100%;
    }
}