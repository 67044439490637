.about {
    scroll-margin: 100px;
    width: 100%;
    max-height: 350px;
    background-color: white;
    display: flex;
    align-items: center;
}

.about__us {
    font-size: 32px;
    margin: 30px 30px;
    padding-left: 3px;
}

.about__summary {
    margin: 30px auto 30px;
    padding: 30px;
    text-indent: 2em;
    /* width: 70%; */
    color: black;
    border-left: 4px solid black;
    font-size: 16px;
    font-weight: 400;
    background-color: whitesmoke;
}

@media screen and (max-width: 700px) {
    .about {
        flex-direction: column;
        max-height: 450px;
    }

    .about__us {
        padding-left: 0;
    }

    .about__summary {
        margin: 0 30px 30px;
        border-top: 4px solid black;
        border-left: none;
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .about {
        max-height: 650px;
    }

    .about__us {
        font-size: 28px;
    }

    .about__summary {
        font-size: 14px;
    }
}