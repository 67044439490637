.map {
    width: 100%;
    /* height: 350px; */
}

.map__caption {
    color: white;
    background-color: black;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
}

.map__element {
    width: 100%;
    height: 400px;
}

@media screen and (max-width: 800px) {
    .map__caption {
        font-size: 16px;
        line-height: 32px;
    } 
}

@media screen and (max-width: 480px) {
    .map__caption {
        font-size: 12px;
        line-height: 20px;
    } 
}