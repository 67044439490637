.scroll-up-button {
    display: none;
}

.scroll-up-button_visible {
    display: block;
    position: fixed;
    right: 35px;
    bottom: 35px;
    width: 50px;
    height: 50px;
    background-color: lightgrey;
    background-image: url(../../images/arrow-up.svg);
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 72;
    font-weight: 700;
    z-index: 1;
    cursor: pointer;
    opacity: .7;
    transition: all .4s ease;
}

.scroll-up-button_visible:hover {
    /* background-color: orange; */
    opacity: 1;
    scale: 1.1;
}

@media screen and (min-width: 780px) {
    .scroll-up-button_visible {
        right: 45px;
        bottom: 45px;
    }
}

@media screen and (min-width: 1280px) {
    .scroll-up-button_visible {
        right: calc((100vw - 1280px)/2 + 50px);
        bottom: 70px;
    }
}