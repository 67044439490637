.rawmat {
    width: 100%;
    background-color: white
}

.rawmat__title {
    scroll-margin: 100px;
    font-size: 32px;
    margin: 30px 30px;
    padding-left: 3px;
    border-left: 9px solid green;
}

.rawmat__option-block {
    display: flex;
}

.rawmat__card {
    width: 100%;
    box-sizing: border-box;
    /* border-bottom: 24px solid black; */
}

.rawmat__option {
    width: 50%;
    flex-basis: 1;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    /* border-bottom: 3px solid black; */
}

.rawmat__option:hover {
    background-color: whitesmoke;
}

.rawmat__option_active {
    background-color: black;
    color: white;
    cursor: default;
    /* text-decoration: underline; */
}

.rawmat__option_active:hover {
    background-color: black;
}

.rawmat__card-content {
    display: flex;
    /* gap: 30px; */
}

.rawmat__card-content_option-2 {
    flex-direction: row-reverse;
}

.rawmat__card-images {
    display: flex;
    flex-direction: column;
}

.rawmat__card-image {
    width: 270px;
    aspect-ratio: 8 / 5;
}

.rawmat__card-text-block {
    padding: 0 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.rawmat__card-text {
    margin-top: 10px;
    padding-right: 10px;
    font-size: 18px;
    text-indent: 2em;
    text-align: left;
}

.rawmat__card-table {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
}

.rawmat__card-table-header {
    padding: 5px 0 5px 0;
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.rawmat__card-table-raw {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.rawmat__card-table-raw:nth-child(odd) {
    background-color: whitesmoke;
  }
  

.rawmat__card-button-block {
    margin: 20px 0;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width: 1000px) {
    .rawmat__card-image {
        width: 200px;
    }

    /* .rawmat__option {
        font-size: 18px;
        line-height: 30px;
    }

    .rawmat__card-text {
        font-size: 14px;
    }

    .rawmat__card-table-header {
        font-size: 14px;
    }
    
    .rawmat__card-table-raw {
        font-size: 14px;
        line-height: 20px;
    } */
}

@media screen and (max-width: 852px) {
    .rawmat__card {
        padding: 0 20px;
    }

    .rawmat__card-content {
        flex-direction: column;
        gap: 0;
    }

    .rawmat__card-images {
        flex-direction: row;
    }

    .rawmat__card-image {
        flex-grow: 1;
        min-width: 100px;
        max-width: 300px;
    }

    .rawmat__option {
        font-size: 18px;
        line-height: 30px;
    }
    
    .rawmat__card-text-block {
        padding: 0;
    }

    /* .rawmat__card-text {
        font-size: 14px;
    }

    .rawmat__card-table-header {
        font-size: 14px;
    }
    
    .rawmat__card-table-raw {
        font-size: 14px;
        line-height: 20px;
    } */
}

@media screen and (max-width: 580px) {
    .rawmat__title {
        scroll-margin: 120px;
        font-size: 24px;
        margin: 26px 26px;
        padding-left: 3px;
        border-left: 6px solid green;
    }

    .rawmat__card {
        padding: 0 10px;
    }

    .rawmat__option {
        font-size: 16px;
    }

    .rawmat__card-button-block {
        flex-direction: column;
        gap: 20px;
    }

    /* .rawmat__card-images {
        flex-direction: column;
        align-items: center;
    } */

    /* .rawmat__card-image {
        width: 250px;
    } */


}