.app {
    width: 100%;
    background-image: url(../images/parquet_fullhd.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    font-family: "Inter", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app__container {
    /* background: #FFF; */
    position: relative;
    color: #000;
    max-width: 1280px;
    min-height: 100vh;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
}