.burger {
    margin: 0;
    padding: 0;
    border: none;
    width: 44px;
    height: 44px;
    padding: 3px 8px;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    z-index: 100;
    flex-shrink: 0;
    transition: opacity 0.4s ease;
}

.burger:hover {
    opacity: 0.8;
}

.burger_clicked {
    position: fixed;
    right: 30px;
}

.burger__bar {
    width: 100%;
    height: 3px;
    flex-shrink: 0;
    background-color: #000;
}

.burger__bar:nth-child(1) {
    rotate: 0deg;
    transition: ease .4s;
}

.burger__bar:nth-child(2) {
    scale: 100%;
    transition: ease .1s;
}

.burger__bar:nth-child(3) {
    rotate: 0deg;
    transition: ease .4s;
}

.burger__bar_clicked:nth-child(1) {
    rotate: 45deg;
    translate: 0 .68em;
    transition: ease .4s;
}

.burger__bar_clicked:nth-child(2) {
    scale: 0%;
    transition: ease .1s;
}

.burger__bar_clicked:nth-child(3) {
    rotate: -45deg;
    translate: 0 -.68em;
    transition: ease .4s;
}

@media screen and (max-width: 320px) {
    .burger {
        width: 40px;
        height: 43px;
        padding: 5px 9px;
    }

    .burger_clicked {
        right: 14px;
    }

    .burger__bar_clicked:nth-child(1) {
        translate: 0 .57em;
    }
    
    .burger__bar_clicked:nth-child(3) {
        translate: 0 -.57em;
    }
}